export class Division {
    id: number
    name: string
    value: string
    shortName: string
    isMajorable: boolean
    
    constructor(id: number, name: string, value: string, shortName: string, is_majorable: boolean) {
        this.id = id
        this.name = name
        this.value = value
        this.shortName = shortName
        this.isMajorable = is_majorable
    }
}

export class DivisionUpdateRequest {
    name: string;
    shortName: string;
    isMajorable: boolean;

    constructor(name: string, shortName: string, is_majorable: boolean) {
        this.name = name
        this.shortName = shortName
        this.isMajorable = is_majorable
    }

    public static fromDivision(division: Division): DivisionUpdateRequest {
        return new DivisionUpdateRequest(division.name, division.shortName, division.isMajorable);
    }
}
