export interface AuthDataEntity {
    result: string;
}

export class AuthData {
    result: string;

    constructor(entity: AuthDataEntity) {
        this.result = entity.result;
    }
    
    static from(entity: AuthDataEntity) {
        return new AuthData(entity)
    }
}