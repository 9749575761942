export function cutDecimal(value: number) {
    return cutDecimalD(value, 4)
}

export function cutDecimalD(value: number, decimal: number) {
    return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function equalIgnore(s1: string, s2: string) {
    return s1.toLocaleLowerCase() == s2.toLocaleLowerCase()
}