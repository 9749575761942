export const constants = {
    AUTHORIZATION_COOKIE : "airsix-Authorization",
    MATCH_TYPE_STEEL_CHALLENGE : "sc",
    MATCH_TYPE_USPSA : "uspsa",
    MATCH_TYPE_IPSC : "ipsc",
    IPSC_STYLE_TYPES : ["USPSA", "IPSC"],
    ORANGE_COLOR : "#E25512"
}

export const classLongNameMap: Map<string, string> = new Map<string, string>([
    ["GM", "GRAND MASTER"],
    ["M", "MASTER"],
    ["A", "A"],
    ["B", "B"],
    ["C", "C"],
    ["D", "D"],
  ]);

export const classColorMap: Map<string, string> = new Map([
  ["GM", "#F73718"],
  ["M", "#663399"],
  ["A", "#336699"],
  ["B", "#028482"],
  ["C", "#c0c0c0"],
  ["D", "#CD7F32"],
])