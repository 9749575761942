export class NewVideoLinkRequest {
    user_id: number;
    match_id: number;
    stage_id: number;
    video_link: string;
    
    constructor (
        user_id: number,
        match_id: number,
        stage_id: number,
        video_link: string,
    ) { 
        this.user_id = user_id
        this.match_id = match_id
        this.stage_id = stage_id
        this.video_link = video_link
    }
}

export interface VideoLinkRequestEntity {
    id: number;
    userId: number;
    matchId: number;
    stageId: number;
    videoLink: string;
    requestStatus: string;
    rejectReason: string;
    requestedAt: string;
    reviewedAt: string | null;
    userName: string;
    matchName: string;
    stageName: string;
}

export class VideoLinkRequest {
    id: number;
    userId: number;
    matchId: number;
    stageId: number;
    videoLink: string;
    requestStatus: string;
    rejectReason: string;
    requestedAt: Date;
    reviewedAt: Date | null;
    userName: string;
    matchName: string;
    stageName: string;

    constructor (
        id: number,
        user_id: number,
        match_id: number,
        stage_id: number,
        video_link: string,
        request_status: string,
        reject_reason: string,
        requested_at: Date,
        reviewed_at: Date | null,
        user_name: string,
        match_name: string,
        stage_name: string
    ) { 
        this.id = id
        this.userId = user_id
        this.matchId = match_id
        this.stageId = stage_id
        this.videoLink = video_link
        this.requestStatus = request_status
        this.rejectReason = reject_reason
        this.requestedAt = requested_at
        this.reviewedAt = reviewed_at
        this.userName = user_name
        this.matchName = match_name
        this.stageName = stage_name
    }

    static from(entity: VideoLinkRequestEntity): VideoLinkRequest {
        return new VideoLinkRequest(
            entity.id,
            entity.userId,
            entity.matchId,
            entity.stageId,
            entity.videoLink,
            entity.requestStatus,
            entity.rejectReason,
            new Date(entity.requestedAt),
            entity.reviewedAt ? new Date(entity.reviewedAt) : null,
            entity.userName,
            entity.matchName,
            entity.stageName,
        )
    }
    
    progressMessage() {
        if (this.requestStatus === "REQUESTED") {
            return "대기"
        }
        if (this.requestStatus === "REVIEWING") {
            return "검토 중"
        }
        if (this.requestStatus === "REJECTED") {
            return `기각(${this.rejectReason})`
        }
        if (this.requestStatus === "ACCEPTED") {
            return "승인"
        }
    }
}

export class DeleteVideoLinkRequest {
    user_id: number;
    match_id: number;
    stage_id: number;
    
    constructor (
        user_id: number,
        match_id: number,
        stage_id: number,
    ) { 
        this.user_id = user_id
        this.match_id = match_id
        this.stage_id = stage_id
    }
}