export class Sport {
    id: number
    name: string
    value: string
    
    constructor(id: number, name: string, value: string) {
        this.id = id
        this.name = name
        this.value = value
    }
}

export class SportUpdateRequest {
    name: string
    
    constructor(name: string) {
        this.name = name
    }

    public static fromSport(sport: Sport): SportUpdateRequest {
        return new SportUpdateRequest(sport.name);
    }
}
