export class Pagination<T> {
    totalPage: number
    currentPage: number
    data: Array<T>

    constructor(totalPage: number, currentPage: number, data: Array<T>) {
        this.totalPage = totalPage
        this.currentPage = currentPage
        if (data === null) {
            this.data = new Array<T>()
        } else {
            this.data = data
        }
    }
}

export class PagingInfo {
    totalPage: number;
    currentPage: number;
    blockSize: number;

    constructor(totalPage: number, currentPage: number, blockSize: number = 5) {
        this.totalPage = totalPage
        this.currentPage = currentPage
        this.blockSize = blockSize
    }

    public isFirstPage(): boolean {
        return this.currentPage === 1
    }

    public isLastPage(): boolean {
        return this.currentPage === this.totalPage
    }

    private getLeftGap(): number {
        return this.blockSize % 2 == 1 ? (this.blockSize - 1) / 2 : this.blockSize / 2 - 1
    }

    private getRightGap(): number {
        return this.blockSize % 2 == 1 ? (this.blockSize - 1) / 2 : this.blockSize / 2
    }

    public isLeftFar(): boolean {
        return this.currentPage - 1 > this.getLeftGap()
    }

    public isRightFar(): boolean {
        return this.totalPage - this.currentPage > this.getRightGap()
    }

    public getPageBlocks(): Array<number> {
        const start = this.currentPage - this.getRightGap() < 1 ? 1 : this.currentPage - this.getRightGap() 
        const end = this.currentPage + this.getRightGap() > this.totalPage ? this.totalPage : this.currentPage + this.getRightGap()

        const result = new Array<number>()

        for (let i = start; i < end + 1; i++) {
            result.push(i)
        }

        return result;
    }
}

export class CountResponse {
    value: number;

    constructor(value: number) {
        this.value = value;
    }
}