function cutDecimalD(value: number, decimal: number) {
    return Math.round(value * Math.pow(10, decimal)) /  Math.pow(10, decimal);
}

export interface ShootStatisticEntity {
    allCount: number;
    alphaCount: number;
    alphaPercent: number;
    charlieCount: number;
    charliePercent: number;
    deltaCount: number;
    deltaPercent: number;
    missCount: number;
    missPercent: number;
    nsCount: number;
}
export class ShootStatistic {
    all_count: number;
    a_count: number;
    a_percent: number;
    c_count: number;
    c_percent: number;
    d_count: number;
    d_percent: number;
    m_count: number;
    m_percent: number;
    ns_count: number;

    constructor(all_count: number,
                a_count: number,
                a_percent: number,
                c_count: number,
                c_percent: number,
                d_count: number,
                d_percent: number,
                m_count: number,
                m_percent: number,
                ns_count: number) {
        this.all_count = all_count
        this.a_count = a_count
        this.a_percent = a_percent
        this.c_count = c_count
        this.c_percent = c_percent
        this.d_count = d_count
        this.d_percent = d_percent
        this.m_count = m_count
        this.m_percent = m_percent
        this.ns_count = ns_count
    }

    static from(entity: ShootStatisticEntity) {
        return new ShootStatistic(
            entity.allCount,
            entity.alphaCount,
            entity.alphaPercent,
            entity.charlieCount,
            entity.charliePercent,
            entity.deltaCount,
            entity.deltaPercent,
            entity.missCount,
            entity.missPercent,
            entity.nsCount
        )
    }


    getApercent(): number {
        return cutDecimalD(this.a_percent, 2) * 100
    }

    getCpercent(): number {
        return cutDecimalD(this.c_percent, 2) * 100
    }

    getDpercent(): number {
        return cutDecimalD(this.d_percent, 2) * 100
    }

    getMpercent(): number {
        return cutDecimalD(this.m_percent, 2) * 100
    }
}
export class MonthlyScoreEntity {
    matchCount: number;
    percent: number;
    stageCount: number;

    constructor(match_count: number,
                percent: number,
                stage_count: number) {
        this.matchCount = match_count
        this.percent = percent
        this.stageCount = stage_count
    }
}
export class MonthlyScore {
    match_count: number;
    percent: number;
    stage_count: number;

    constructor(match_count: number,
                percent: number,
                stage_count: number) {
        this.match_count = match_count
        this.percent = percent
        this.stage_count = stage_count
    }

    static from(entity: MonthlyScoreEntity) {
        return new MonthlyScore(entity.matchCount, entity.percent, entity.stageCount)
    }

    getPercent(): number {
        return cutDecimalD(this.percent * 100, 2)
    }
}

export interface UserStatisticsEntity {
    shootCount: ShootStatisticEntity;
    stageCount: number;
    dqCount: number;
    matches: Array<number>;
    monthScore: Map<string, Map<string, MonthlyScoreEntity>>;
    allScore: Map<string, MonthlyScoreEntity>;
}

export class UserStatistics {
    shoot_count: ShootStatistic;
    stage_count: number;
    dq_count: number;
    matches: Array<number>;
    month_score: Map<number, Map<string, MonthlyScore>>;
    all_score: Map<number, MonthlyScore>;

    constructor(dq_count: number,
                matches: Array<number>,
                month_score: Map<number, Map<string, MonthlyScore>>,
                all_score: Map<number, MonthlyScore>,
                shoot_count: ShootStatistic,
                stage_count: number) {
        this.shoot_count = Object.setPrototypeOf(shoot_count, ShootStatistic.prototype);
        this.stage_count = stage_count
        this.dq_count = dq_count
        this.matches = matches

        // const monthScore = new Map<string, Map<string, MonthlyScore>>()
        // for (let [divisionId, monthScoreMap] of month_score.entries()) {

        //     const ms = new Map<string, MonthlyScore>()
        //     for (let [month, score] of monthScoreMap.entries()) {
        //         ms.set(month, Object.setPrototypeOf(score, MonthlyScore.prototype))
        //     }

        //     monthScore.set(divisionId, ms)
        // }
        this.month_score = month_score 

        // const as = new Map<string, TotalMonthlyScore>()
        // for (const score of Object.entries(month_score)) {
        //     Object.setPrototypeOf(score[1], MonthlyScore.prototype);
        //     ms.set(score[0], Object.setPrototypeOf(score[1], MonthlyScore.prototype))
        // }
        this.all_score = all_score 
    }

    static from(entity: UserStatisticsEntity) {
        const month_score = new Map<number, Map<string, MonthlyScore>>()
        const all_score = new Map<number, MonthlyScore>()
    
        const division_month_map: Map<string, Map<string, MonthlyScoreEntity>> = new Map(Object.entries(entity.monthScore))

        for (const divisionId of division_month_map.keys()){
            const month_map: Map<string, MonthlyScoreEntity> = new Map(Object.entries(division_month_map.get(divisionId)!!))

            const ms = new Map<string, MonthlyScore>()
            for (const month of month_map.keys()){
                ms.set(month, MonthlyScore.from(month_map.get(month)!!))
            }

            month_score.set(Number.parseInt(divisionId), ms)
        }
        
        const division_month_total_map: Map<string, MonthlyScoreEntity> = new Map(Object.entries(entity.allScore))

        for (const [divisionId, monthlyScoreEntity] of division_month_total_map.entries()){
            all_score.set(Number.parseInt(divisionId), MonthlyScore.from(monthlyScoreEntity))
        }
        
        return new UserStatistics(
            entity.dqCount,
            entity.matches,
            month_score,
            all_score,
            ShootStatistic.from(entity.shootCount),
            entity.stageCount
        )
    }

    public getAllScoreList(): Array<[number, MonthlyScore]> {
        const entries = Array.from(this.all_score.entries())
        entries.sort((a, b) =>  b[1].percent - a[1].percent)
        return entries
    }

    static sample() {
        const monthScore = new Map<number, Map<string, MonthlyScore>>()
        const allScore = new Map<number, MonthlyScore>()

        allScore.set(1, new MonthlyScore(2, 0.70394895, 8))
        allScore.set(2, new MonthlyScore(2, 0.75694895, 8))
        
        const ms = new Map<string, MonthlyScore>()
        ms.set("2022-8", new MonthlyScore(1, 0.720468311, 4))
        ms.set("2022-9", new MonthlyScore(1, 0.6874296, 8))
        monthScore.set(1, ms)
        
        const ms2 = new Map<string, MonthlyScore>()
        ms2.set("2022-8", new MonthlyScore(1, 0.751240468311, 4))
        ms2.set("2022-9", new MonthlyScore(1, 0.6612424296, 8))
        monthScore.set(2, ms2)

        return new UserStatistics(
            0,
            [119, 118],
            monthScore,
            allScore,
            new ShootStatistic(
                144, 112, 0.777777778, 26, 0.18055555, 3, 0.020833333, 3, 0.020833333, 1
            ),
            8
        )
    }
}