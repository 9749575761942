export class ImageSizeOverError extends Error {
    constructor(msg: string) {
        super(msg);
        Object.setPrototypeOf(this, ImageSizeOverError.prototype);
    }
}

export class NotImageFileError extends Error {
    constructor(msg: string) {
        super(msg);
        Object.setPrototypeOf(this, NotImageFileError.prototype);
    }
}

export class NoFileError extends Error {
    constructor(msg: string) {
        super(msg);
        Object.setPrototypeOf(this, NoFileError.prototype);
    }
}

// https://www.bottlehs.com/vue/vue-js-%ED%8C%8C%EC%9D%BC%EC%97%85%EB%A1%9C%EB%93%9C/#vue-file-upload 감사합니다.
export function readUploadFile(e: Event, readerFunction: (e: Event) => void, maxFileSizeMB: number): File {
    const uploadFile = e.target as HTMLInputElement;
    if (uploadFile != null) {
        if (uploadFile.files!!.length > 0) {
            const imageFile = uploadFile.files!!.item(0)!!

            const fileName = imageFile.name;
            const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

            if (["jpeg", "jpg", "png", "bmp"].includes(fileExt)) {
                if (imageFile.size > maxFileSizeMB) {
                    throw new ImageSizeOverError(`파일을 다시 선택해 주세요. - 파일의 크기가 너무 큽니다.(${maxFileSizeMB}MB 이하로 업로드 가능).`);
                } else {
                    const reader = new FileReader();
                    reader.onload = readerFunction;
                    reader.readAsDataURL(imageFile);
                    return imageFile
                }
            } else {
                throw new NotImageFileError("파일을 다시 선택해 주세요. - 이미지 파일만 업로드 가능합니다.");
            }
        } else {
            throw new NoFileError("")
        }
    } else {
        throw new NoFileError("")
    }
}