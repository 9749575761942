export interface EloChangeEntity {
    id: number;
    eloId: number;
    matchId: number;
    userId: number;
    eloPre: number;
    eloChange: number;
    eloPost: number;
}

export class EloChange {
    id: number;
    elo_id: number;
    match_id: number;
    user_id: number;
    elo_pre: number;
    elo_change: number;
    elo_post: number;

    constructor (id: number,
                elo_id: number,
                match_id: number,
                user_id: number,
                elo_pre: number,
                elo_change: number,
                elo_post: number
    ) { 
        this.id = id
        this.elo_id = elo_id
        this.match_id = match_id
        this.user_id = user_id
        this.elo_pre = elo_pre
        this.elo_change = elo_change
        this.elo_post = elo_post
    }

    static from(entity: EloChangeEntity): EloChange {
        return new EloChange(
            entity.id,
            entity.eloId,
            entity.matchId,
            entity.userId,
            entity.eloPre,
            entity.eloChange,
            entity.eloPost,
        )
    }
}
