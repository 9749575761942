import { createRouter, createWebHistory } from 'vue-router'

import AdminPage from '@/components/admin/AdminPage.vue'
import AdminBase from '@/components/admin/AdminBase.vue'
import AdminShooterList from '@/components/admin/AdminShooterList.vue'
import AdminShooterWithoutEmailList from '@/components/admin/AdminShooterWithoutEmailList.vue'
import AdminUserMergePage from '@/components/admin/AdminUserMergePage.vue'
import AdminMatchUpload from '@/components/admin/AdminMatchUpload.vue'
import AdminMatchMerge from '@/components/admin/AdminMatchMerge.vue'
import AdminMatchUpdate from '@/components/admin/AdminMatchUpdate.vue'
import AdminMatchlist from '@/components/admin/AdminMatchList.vue'
import AdminRangeList from '@/components/admin/AdminRangeList.vue'
import AdminCarouselList from '@/components/admin/AdminCarouselList.vue'
import AdminVideoLinkRequest from '@/components/admin/AdminVideoLinkRequest.vue'
import SportManagePage from '@/components/admin/SportManagePage.vue'
import DivisionManagePage from '@/components/admin/DivisionManagePage.vue'
import ToyBase from '@/components/toys/ToyBase.vue'
import ToyPage from '@/components/toys/ToyPage.vue'
import ShooterShufflePage from '@/components/toys/ShooterRandomOrder.vue'
import MatchBase from '@/components/MatchBase.vue'
import Match from '@/components/MatchPage.vue'
import MatchInfo from '@/components/MatchInfoPage.vue'
import ShooterBase from '@/components/ShooterBase.vue'
import Shooters from '@/components/ShootersPage.vue'
import ShooterProfile from '@/components/ShooterProfile.vue'
import ShooterProfileUpdate from '@/components/ShooterProfileUpdate.vue'
import ShooterPasswordUpdate from '@/components/ShooterPasswordUpdate.vue'
import ShooterVideoRequests from '@/components/ShooterVideoRequests.vue'
import RulePage from '@/components/RulePage.vue'
import MapBook from '@/components/MapBook.vue'
import Register from '@/components/RegisterPage.vue'
import Login from '@/components/LoginPage.vue'
import UnauthorizedError from '@/components/errorPage/UnauthorizedError.vue'

const routes = [
    {
      path: "/matches",
      component: MatchBase,
      children: [
        { path: "", component: Match },
        { path: ":id", component: MatchInfo }
      ]
    },
    {
      path: "/shooters",
      component: ShooterBase,
      children: [
        { path: "", component: Shooters },
        { path: ":id", component: ShooterProfile },
        { path: ":id/update", component: ShooterProfileUpdate },
        { path: ":id/password-update", component: ShooterPasswordUpdate },
        { path: ":id/video-requests", component: ShooterVideoRequests },
      ]
    },
    {
      path: "/admin",
      component: AdminBase,
      children: [
        { path: "", component: AdminPage },
        { path: "shooters", component: AdminShooterList},
        { path: "shooters/without-email", component: AdminShooterWithoutEmailList},
        { path: "shooters/merge", component: AdminUserMergePage},
        { path: "match-upload", component: AdminMatchUpload},
        { path: "matches", component: AdminMatchlist},
        { path: "match-merge", component: AdminMatchMerge},
        { path: "matches/:id", component: AdminMatchUpdate},
        { path: "sports", component: SportManagePage},
        { path: "divisions", component: DivisionManagePage},
        { path: "shooter-shuffle-list", component: ShooterShufflePage},
        { path: "video-link-requests", component: AdminVideoLinkRequest},
        { path: "carousels", component: AdminCarouselList},
        { path: "ranges", component: AdminRangeList},
      ]
    },
    {
      path: "/toys",
      component: ToyBase,
      children: [
        { path: "", component: ToyPage },
        { path: "shooter-shuffle-list", component: ShooterShufflePage},
      ]
    },
    { path: "/rules", component: RulePage },
    { path: "/map-book", component: MapBook },
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/403", component: UnauthorizedError},
    { path: "", component: Match},
  ];

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router