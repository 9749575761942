<template>
  <Bar :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  PointElement,
  CategoryScale,
  ChartDataLabels
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    plugins: [ChartDataLabels],
    chartId: {
      type: String,
      default: "line-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              min: 0,
              max: 100,
              grid: {
                display: false,
              },
            },
          },
          layout: {
            padding: {
              top: 50, // Add 30 pixels of padding to top of chart.
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              align: "end",
              anchor: "end",
              color: "black",
              font: {
                // weight: "bold",
                size: 10,
              },
              formatter: (e) => Math.round(e),
            },
          },
        };
      },
    },
  },
};
</script>
