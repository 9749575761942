import { AuthDataEntity, AuthData } from '@/components/data/authData';
import { CarouselInfo, CarouselInfoEntity } from '@/components/data/carousel';
import { Sport } from '@/components/data/sport';
import { Division } from '@/components/data/division';
import { User, UserEntity } from './components/data/shooter';
import { Match, MatchEntity, AvailableFilters, SimpleMatchEntity, SimpleMatch } from '@/components/data/matchResult/match';
import { Shooter } from '@/components/data/shooter';
import { UserStatistics, UserStatisticsEntity } from '@/components/data/statistic';
import { Pagination } from '@/components/data/response';
import { NewVideoLinkRequest, VideoLinkRequest, VideoLinkRequestEntity, DeleteVideoLinkRequest} from '@/components/data/videoLinkRequest';
import axios from "axios";
import {Season} from "@/components/data/season";

export const airsixAxios = axios.create({
    // baseURL: "https://air-six.com",
    headers: {
        "content-type": "application/json",
        Accept: "application/json"
    }
})

export const getCarouselData = () => airsixAxios.get<CarouselInfoEntity[]>(`/api/carousels`)
.then((r) => {
    const original_list = r.data.sort((a, b) => a.order - b.order).map(e => CarouselInfo.of(e));
    for (let i = 0; i < original_list.length; i++) {
        original_list[i].show_order = i;
    }
    return original_list
});

export const getDivisions = async () => {
    const r = await airsixAxios.get<Division[]>(`/api/divisions`)
    return r.data
}
export const getSeasons = async () => {
    const r = await airsixAxios.get<Season[]>(`/api/seasons`)
    return r.data
}

export const getDivisionMap = async () => {
    const divisions = await getDivisions()
    const divMap = new Map<number, Division>();

    divisions.forEach((d) => {
      divMap.set(d.id, d);
    });

    return divMap
}

export const getSeasonMap = async () => {
    const seasons = await getSeasons()
    const seasonMap = new Map<number, Season>();

    seasons.forEach((d) => {
      seasonMap.set(d.id, d);
    });

    return seasonMap
}

export const getSports = async () => {
    const r = await airsixAxios.get<Sport[]>(`/api/sports`)
    return r.data
}

export const getSportsMap = async () => {
    const sports = await getSports()
    const sMap = new Map<number, Sport>();

    sports.forEach((d) => {
        sMap.set(d.id, d);
    });

    return sMap
}

export const getSportsValueMap = async () => {
    const sports = await getSports()
    const sMap = new Map<string, Sport>();

    sports.forEach((d) => {
        sMap.set(d.value, d);
    });

    return sMap
}

export const login = (request: object) => airsixAxios.post<string>(`/api/login`, request)

export const register = (request: object) => airsixAxios.post<string>(`/api/register`, request)

export const validateToken = (token: string) => airsixAxios.get<AuthDataEntity>(`/api/auth/validate?token=${token}`).then((r) => AuthData.from(r.data));

export const validateAndReissueToken = (token: string) => airsixAxios.get<AuthDataEntity>(`/api/auth/validate-reissue?token=${token}`).then((r) => AuthData.from(r.data));

export const getAvailableFilters = async (types: string[], tags: string[] = [], ranges: string[] = []) => {
    let uri = `/api/matches/available-filters`

    const type_query = types.map(t => `types=${t}`).join("&")
    const tag_query = tags.map(t => `tags=${t}`).join("&")
    const range_query = ranges.map(t => `ranges=${t}`).join("&")

    const query_params = [type_query, tag_query, range_query].filter(q => q.length > 0).join("&")

    if (query_params.length > 0) {
        uri = `${uri}?${query_params}`
    }

    return (await airsixAxios.get<AvailableFilters>(uri)).data
}

export const getMatches = async (
    page: number,
    size: number,
    order: string = 'ASC',
    tags: string[] = [],
    types: string[] = [],
    ranges: string[] = []) => {
    let uri = `/api/matches?page=${page}&size=${size}&sort=ID&order=${order}`

    const type_query = types.map(t => `types=${t}`).join("&")
    const tag_query = tags.map(t => `tags=${t}`).join("&")
    const range_query = ranges.map(t => `ranges=${t}`).join("&")

    const filter_query_params = [type_query, tag_query, range_query].filter(q => q.length > 0).join("&")

    if (filter_query_params.length > 0) {
        uri = `${uri}&${filter_query_params}`
    }

    return (await airsixAxios.get<Pagination<MatchEntity>>(uri).then((r) => {
        return new Pagination<Match>(
            r.data.totalPage,
            r.data.currentPage,
            r.data.data.map(e => Match.from(e))
        )
    }))
}

export const getMatchById = async (matchId: number) => {
    const response = await airsixAxios.get<MatchEntity>(`/api/matches/${matchId}`)
    return Match.from(response.data)
}

// sort
// sort option is fixed to 1 when name parameter is exists.
export const getUsers = (page: number, size: number, name: string, sort: string = 'ID', order: string = 'ASC') => {
    let uri = `/api/users?page=${page}&size=${size}`

    if (name.length > 0) {
        uri = `${uri}&userName=${name}&sort=ID&order=ASC`
    } else {
        uri = `${uri}&sort=${sort}&order=${order}`
    } 

    return airsixAxios.get<Pagination<UserEntity>>(uri).then((r) => {
        return new Pagination<User>(
            r.data.totalPage,
            r.data.currentPage,
            r.data.data.map(e => User.from(e))
        )
    })
}

export const getUserById = (userId: number) => airsixAxios.get<UserEntity>(`/api/users/${userId}`).then((r) => User.from(r.data))

export const getUserByIds = (userIds: Array<number>) => airsixAxios.get<Array<UserEntity>>(`/api/users/ids?userIds=${userIds.join('&userIds=')}`).then((r) => r.data.map(e => User.from(e)))

export const getUserRecentMatches = (userId: number, page: number = 1, size: number = 3) =>
 airsixAxios.get<Pagination<SimpleMatchEntity>>(`/api/users/${userId}/profile/matches?page=${page}&size=${size}`).then((r) => {
    return new Pagination<SimpleMatch>(
        r.data.totalPage,
        r.data.currentPage,
        r.data.data.map(e => SimpleMatch.of(e))
    )
})

export const updateUserInfo = (user: User) => airsixAxios.request({
    url: `/api/users/${user.id}/profile`,
    method: 'patch',
    data: user
})

export const updatePassword = async (userId: number, request: object) => airsixAxios.request({
    url: `/api/users/${userId}/profile/password`,
    method: 'patch',
    data: request
})

export const getUserStatistics = (userId: number, startYear: number, startMonth: number, endYear: number, endMonth: number) => 
  airsixAxios.get<UserStatisticsEntity>(`/api/users/${userId}/profile/statistics?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`)
  .then(response => UserStatistics.from(response.data));

export const getUserVideoLinkRequests = (userId: number, page: number = 1, size: number = 10) => 
  airsixAxios.get<Pagination<VideoLinkRequestEntity>>(`/api/users/${userId}/profile/video-requests?page=${page}&size=${size}`).then(r => {
    return new Pagination<VideoLinkRequest>(
        r.data.totalPage,
        r.data.currentPage,
        r.data.data.map(e => VideoLinkRequest.from(e))
    )
});

export const makeNewVideoLinkRequests = (userId: number, request: NewVideoLinkRequest) => airsixAxios.post<string>(`/api/users/${userId}/profile/video-requests`, request)

export const deleteVideoLinkRequests = (userId: number, request: DeleteVideoLinkRequest) =>
    airsixAxios.delete<string>(`/api/users/${userId}/profile/video-requests?user_id=${request.user_id}&match_id=${request.match_id}&stage_id=${request.stage_id}`)

export const updateUserProfileImage = (user: User, data: FormData) =>
    airsixAxios.post(`/api/users/${user.id}/profile/profile-image`, data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    );

export const getRankingByDivision = (seasonId: number, divisionId: number) =>
    airsixAxios.get<Pagination<UserEntity>>(`/api/users/rank?seasonId=${seasonId}&divisionId=${divisionId}`).then((r) => r.data.data.map(e => User.from(e)))
