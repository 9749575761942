import { app } from "../../main"
import { useCookies } from "vue3-cookies";
import { constants } from "@/constants";


const cookies = useCookies();


export class UserToken {
    id: number
    name: string
    isAdmin: boolean
    issued_at: number
    expired: number
    authorities: Array<string>

    constructor(id: number, name: string, isAdmin: boolean, issued_at: number, expired: number, authorities: Array<string>) {
        this.id = id
        this.name = name;
        this.isAdmin = isAdmin;
        this.issued_at = issued_at;
        this.expired = expired;
        this.authorities = authorities;
    }

    public adminPageAccessible() {
        return this.isAdmin || this.authorities.length > 0
    }

    public checkAuthority(additional_authority: Array<string> = []): boolean {
        if (additional_authority.length > 0) {
            const all_auth_in = additional_authority.every(v => this.authorities.includes(v));
            return this.isAdmin || all_auth_in
        } else {
            return this.isAdmin
        }
    }

    public getTimeTillExpire(): number {
        return (this.expired * 1000) - Date.now();
    }

    public isExpired(): boolean {
        return this.getTimeTillExpire() < 0;
    }
}

export function parseJwtBody (token: string): UserToken {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const parsed = JSON.parse(jsonPayload);
    const authorities = parsed.authorities === undefined ? [] : parsed.authorities

    return new UserToken(Number.parseInt(parsed.sub), parsed.name, parsed.isAdmin, parsed.iat, parsed.exp, authorities)
}

export function getAuthToken(): string {
    return cookies.cookies.get(constants.AUTHORIZATION_COOKIE);
}