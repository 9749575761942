<script setup lang="ts"></script>

<template>
  <h2>Toy Page</h2>
  <ul>
    <li>
      <router-link v-bind:to="`/toys/shooter-shuffle-list`">
        슈터 목록 만들기
      </router-link>
    </li>
  </ul>
</template>

<style></style>
