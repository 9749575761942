import { Stage, StageEntity } from "./matchResult/match"


interface ClassifierEntity {
    id: number
    userId: number
    divisionId: number
    clazz: string  // GM M A B C D
    percentage: number
    season: string
    createdAt: string
}

export class Classifier {
    id: number
    divisionId: number
    clazz: string  // GM M A B C D
    percentage: number
    season: string
    valid: boolean
    created_at: Date

    constructor (id: number, divisionId: number, clazz: string, range: number, season: string, created_at: string) {
        this.id = id
        this.divisionId = divisionId
        this.clazz = clazz
        this.percentage = range
        this.season = season
        
        this.created_at = new Date(created_at)
        const now = new Date()
        
        let months = (now.getFullYear() - this.created_at.getFullYear()) * 12;
        months += now.getMonth();
        months -= this.created_at.getMonth();
        this.valid = months <= 3
    }

    static from(entity: ClassifierEntity): Classifier {
        return new Classifier(entity.id, entity.divisionId, entity.clazz, entity.percentage, entity.season, entity.createdAt)
    }

    toBadgeString(divisionName: string): string {
        if (divisionName.length < 1) {
            return this.season + " " +  this.clazz
        } else {
            return this.season + " " + divisionName + " - " + this.clazz
        }
    }

    toSimpleString(divisionName: string): string {
        if (divisionName.length < 1) {
            return this.clazz
        } else {
            return divisionName + "/" + this.clazz
        }
    }
}

export interface UserEntity {
    id: number;
    name: string;
    email: string;
    profileImg: string;
    sport: Array<string>;
    division: Array<string>;
    classifiers: Array<ClassifierEntity>;
    level: string;
    powerFactor: string;
    elo: number;
    team: string;
    weapons:Array<string>;
    optics:Array<string>;
    description: string;
    // for admin
    initiated: boolean;
    created_at: string;
    isSuperuser: boolean;
    isVisible: boolean;
}

export class User {
    id: number;
    name: string;
    email: string;
    profile_img: string;
    sport: Array<string>;
    division: Array<string>;
    classifiers: Array<Classifier>;
    level: string;
    power_factor: string;
    elo: number;
    team: string;
    weapons:Array<string>;
    optics:Array<string>;
    description: string;
    is_visible: boolean;

    constructor(id: number, name: string, email: string, profile_img: string,
                team: string, division: Array<string>, classifiers: Array<Classifier>,
                level: string, sport: Array<string>, optics:Array<string>, weapons:Array<string>, power_factor: string,
                elo: number, description: string, is_visible: boolean) {
        this.id = id
        this.name = name
        this.email = email
        this.team = team
        this.division = division
        this.classifiers = classifiers
        this.level = level
        this.power_factor = power_factor
        this.elo = elo
        this.sport = sport
        this.profile_img = profile_img
        this.optics = optics
        this.weapons = weapons
        this.description = description
        this.is_visible = is_visible

        this.classifiers.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
    }

    static from(entity: UserEntity): User {
        return new User(
            entity.id,
            entity.name,
            entity.email,
            entity.profileImg,
            entity.team,
            entity.division,
            entity.classifiers.map(e => Classifier.from(e)),
            entity.level,
            entity.sport,
            entity.optics,
            entity.weapons,
            entity.powerFactor,
            entity.elo,
            entity.description,
            entity.isVisible
        )
    }

    public getValidClassifierResult(): Classifier | null {
        if (this.classifiers.length < 1) return null
        
        const filtered = this.classifiers.filter(c => c.valid)

        return filtered.length > 0 ? filtered[0] : null
    }

    public getClassifiers(getAll: boolean) {
        return getAll ? this.classifiers : this.classifiers.slice(0, 3)
    }
}

export class AdminPageUser {
    id: number;
    name: string;
    email: string;
    profile_img: string;
    sport: Array<string>;
    division: Array<string>;
    level: string;
    power_factor: string;
    team: string;
    weapons:Array<string>;
    optics:Array<string>;
    description: string;
    initiated: boolean;
    created_at: string;
    is_superuser: boolean;
    is_visible: boolean;

    constructor(id: number, name: string, email: string, profile_img: string,
                team: string, division: Array<string>, level: string,
                sport: Array<string>, optics:Array<string>, weapons:Array<string>, power_factor: string, description: string,
                initiated: boolean, created_at: string, is_superuser: boolean, is_visible: boolean) {
        this.id = id
        this.name = name
        this.email = email
        this.team = team
        this.division = division
        this.level = level
        this.power_factor = power_factor
        this.sport = sport
        this.profile_img = profile_img
        this.optics = optics
        this.weapons = weapons
        this.description = description
        this.initiated = initiated
        this.created_at = created_at
        this.is_superuser = is_superuser
        this.is_visible = is_visible
    }

    static from(entity: UserEntity): AdminPageUser {
        return new AdminPageUser(
            entity.id,
            entity.name,
            entity.email,
            entity.profileImg,
            entity.team,
            entity.division,
            entity.level,
            entity.sport,
            entity.optics,
            entity.weapons,
            entity.powerFactor,
            entity.description,
            entity.initiated,
            entity.created_at,
            entity.isSuperuser,
            entity.isVisible
        )
    }

    clone() {
        return new AdminPageUser(
            this.id,
            this.name,
            this.email,
            this.profile_img,
            this.team,
            this.division,
            this.level,
            this.sport,
            this.optics,
            this.weapons,
            this.power_factor,
            this.description,
            this.initiated,
            this.created_at,
            this.is_superuser,
            this.is_visible
        )
    }
}

export interface ShooterEntity {
    id: number;
    email: string;
    userId: number;
    name: string;
    sport: number;
    division: number;
    powerFactor: string;
    category: string;
    level: string;
}

export class Shooter {
    id: number;
    email: string;
    user_id: number;
    name: string;
    sport: number;
    division: number;
    power_factor: string;
    category: string;
    level: string;

    constructor(id: number,
        email: string,
        user_id: number,
        name: string,
        sport: number,
        division: number,
        power_factor: string,
        category: string,
        level: string) {
        this.id = id
        this.user_id = user_id
        this.email = email
        this.name = name
        this.sport = sport
        this.division = division
        this.power_factor = power_factor
        this.category = category
        this.level = level
    }

    static from(entity: ShooterEntity) {
        return new Shooter(
            entity.id,
            entity.email,
            entity.userId,
            entity.name,
            entity.sport,
            entity.division,
            entity.powerFactor,
            entity.category,
            entity.level,
        )
    }
}

export class SimpleUser {
    id: number;
    name: string;
    team: string;
    profile_img: string;
    level: string;
    description: string;
    classifier: Classifier | null
    elo: number;

    constructor(id: number, name: string, profile_img: string,
        team: string, level: string, description: string,
        classifier: Classifier | null, elo: number) {
        this.id = id
        this.name = name
        this.team = team
        this.profile_img = profile_img
        this.level = level
        this.description = description
        this.classifier = classifier
        this.elo = elo
    }

    static of(user: User): SimpleUser {

        return new SimpleUser(
            user.id,
            user.name,
            user.profile_img,
            user.team,
            user.level,
            user.description,
            user.getValidClassifierResult(),
            user.elo
        )
    }
}

export class UserMergeRequest {
    from: number;
    to: number;

    constructor(from: number, to: number) {
        this.from = from;
        this.to = to;
    }
}


export interface CheckUserListEntity {
    notRegisteredUser: Array<ShooterEntity>;
    emptyMailUser: Array<ShooterEntity>;
    registeredUser: Array<ShooterEntity>;
}

export class CheckUserList {
    notRegisteredUser: Array<Shooter>;
    emptyMailUser: Array<Shooter>;
    registeredUser: Array<Shooter>;

    constructor(not_registered_user: Array<Shooter>,
                empty_mail_user: Array<Shooter>,
                registered_user: Array<Shooter>) {
        this.notRegisteredUser = not_registered_user
        this.emptyMailUser = empty_mail_user
        this.registeredUser = registered_user
    }

    static from(entity: CheckUserListEntity) {
    return new CheckUserList(
        entity.notRegisteredUser.map(s => Shooter.from(s)),
        entity.emptyMailUser.map(s => Shooter.from(s)),
        entity.registeredUser.map(s => Shooter.from(s))
    )
    }
}

export interface MatchCheckResultEntity {
    range: Range;
    matchName: string;
    sport: string;
    isNewSport: boolean;
    shooterList: CheckUserListEntity;
}

export class MatchCheckResult {
    range: Range;
    matchName: string;
    sport: string;
    isNewSport: boolean;
    shooterList: CheckUserList;

    constructor(range: Range,
                match_name: string,
                sport: string,
                is_new_sport: boolean,
                shooter_list: CheckUserList) {
        this.range = range
        this.matchName = match_name
        this.sport = sport
        this.isNewSport = is_new_sport
        this.shooterList = shooter_list
    }

    static from(entity: MatchCheckResultEntity) {
        return new MatchCheckResult(
            entity.range,
            entity.matchName,
            entity.sport,
            entity.isNewSport,
            CheckUserList.from(entity.shooterList)
        )
    }
}
export interface MatchMergeCheckResultEntity {
    match_name: string;
    shooterList: Array<ShooterEntity>;
    stageList: Array<StageEntity>;
}

export class MatchMergeCheckResult {
    matchName: string;
    shooterList: Array<Shooter>;
    stageList: Array<Stage>;

    constructor(match_name: string,
                shooterList: Array<Shooter>,
                stageList: Array<Stage>) {
        this.matchName = match_name
        this.shooterList = shooterList
        this.stageList = stageList
    }

    static from(entity: MatchMergeCheckResultEntity) {
        return new MatchMergeCheckResult(
            entity.match_name,
            entity.shooterList.map(s => Shooter.from(s)),
            entity.stageList.map(s => new Stage(s)),
        )
    }
}