import { Range } from '@/components/data/range';
import { Shooter, ShooterEntity, User } from '@/components/data/shooter';
import { EloChange, EloChangeEntity } from '@/components/data/elo';
import { constants } from '@/constants';
import { cutDecimal, cutDecimalD, equalIgnore } from '@/components/script/utils'

export interface AvailableFilters {
    tags: AvailableTag[]
    types: AvailableType[]
    ranges: AvailableRange[]
}


export interface AvailableTag {
    tag: string
    count: number
}

export interface AvailableType {
    type: string
    count: number
}

export interface AvailableRange {
    range: Range
    count: number
}

export class Target {
    sort: number
    reqshots: number
    maxnpms: number

    constructor(sort: number, reqshots: number, maxnpms: number) {
        this.sort = sort
        this.reqshots = reqshots
        this.maxnpms = maxnpms
    }
}


export interface StageEntity {
    id: number;
    sort: number
    name: string;
    scoreType: string;
    targets: Array<Target>;
    popperCnt: number;
    stageImg: string;
    point: number
    scores: Array<StageScoreEntity>
}

export class Stage {
    id: number;
    sort: number
    name: string;
    score_type: string;
    targets: Array<Target>;
    popper_cnt: number;
    stage_img: string;
    point: number
    scores: Array<StageScore>

    constructor(entity: StageEntity) {
        this.id = entity.id
        this.name = entity.name
        this.sort = entity.sort
        this.score_type = entity.scoreType
        this.targets = entity.targets.map(t => new Target(t.sort, t.reqshots, t.maxnpms))
        this.popper_cnt = entity.popperCnt
        this.stage_img = entity.stageImg
        this.point = entity.point
        this.scores = entity.scores.map(s => new StageScore(s))
    }

    public getMaxStageScore(): number {
        const totalAlpha = this.targets.map(t => t.reqshots).reduce((a, b) => a + b, 0)
        return 5 * (totalAlpha + this.popper_cnt)
    }
}

export interface StageScoreEntity {
    id: number;
    shooterId: number;
    userId: number;
    stageId: number;
    videoLink: string;
    times: Array<number>;
    timeRecord: Array<Array<string>>;
    popperHit: number;
    popperMiss: number;
    popperNoShoot: number;
    popperNpm: number;
    scoreA: number;
    scoreB: number;
    scoreC: number;
    scoreD: number;
    scoreNs: number;
    scoreM: number;
    scoreNpm: number;
    apen: number  // 페널티
    isDq: boolean
    points: number
    hitFactor: number
    stagePts: number
    stagePercent: number
    powerFactor: string
}

export class StageScore {
    id: number;
    shooter_id: number;
    user_id: number;
    stage_id: number;
    video_link: string;
    times: Array<number>;
    time_record: Array<Array<string>>;
    popper_hit: number;
    popper_miss: number;
    popper_no_shoot: number;
    popper_npm: number;
    score_a: number;
    score_b: number;
    score_c: number;
    score_d: number;
    score_ns: number;
    score_m: number;
    score_npm: number;
    apen: number  // 페널티
    is_dq: boolean
    points: number
    hit_factor: number
    stage_pts: number
    stage_percent: number
    power_factor: string

    constructor(entity: StageScoreEntity) {
        this.id = entity.id
        this.shooter_id = entity.shooterId
        this.user_id = entity.userId
        this.stage_id = entity.stageId
        this.video_link = entity.videoLink
        this.times = entity.times
        this.time_record = entity.timeRecord
        this.popper_hit = entity.popperHit
        this.popper_miss = entity.popperMiss
        this.popper_no_shoot = entity.popperNoShoot
        this.popper_npm = entity.popperNpm
        this.score_a = entity.scoreA
        this.score_b = entity.scoreB
        this.score_c = entity.scoreC
        this.score_d = entity.scoreD
        this.score_ns = entity.scoreNs
        this.score_m = entity.scoreM
        this.score_npm = entity.scoreNpm
        this.apen = entity.apen
        this.is_dq = entity.isDq
        this.points = entity.points
        this.hit_factor = cutDecimal(entity.hitFactor)
        // overall pts
        this.stage_pts = entity.stagePts
        // overall percent
        this.stage_percent = entity.stagePercent
        this.power_factor = entity.powerFactor
    }

    public getIpscTime(): number {
        const timeSum =this.times.reduce((a, b) => a + b, 0);
        return cutDecimalD(timeSum, 2)
    }

    public getSteelChallengeTime(): number {
        const min = Math.max(...this.times)
        const sum = this.times.reduce((sum, current) => sum + current, 0);
        return cutDecimalD(sum - min, 2);
    }

    public checkStageOption(stageOption: string): boolean {
        return stageOption.toUpperCase() === 'default'.toUpperCase() ? true : equalIgnore(this.stage_id.toString(), stageOption)
    }

    public checkDivisionOption(divisionOption: string, shooterMap: Map<number, Shooter>): boolean {
        return ['overall', 'by-division'].includes(divisionOption) ? true : equalIgnore(shooterMap.get(this.shooter_id)?.division.toString() || "", divisionOption)
    }

    public checkUserIdOption(userId: number): boolean {
        return userId !== null ? this.user_id === userId : true
    }

    public recalculateHitFactor(stage: Stage) {
        if (this.is_dq) {
            this.points = 0
            this.hit_factor = 0
        } else {
            const alphaScore = (this.score_a + this.popper_hit) * 5;
            const charlieScore = this.score_c * (this.power_factor.toLowerCase() === "minor" ? 3 : 4);
            const deltaScore =  this.score_d * 1;
            const penalty = (this.popper_miss + this.score_m + this.score_ns + this.popper_no_shoot) * 10 + this.apen

            this.points = Math.max(0, alphaScore + charlieScore + deltaScore - penalty)
            const time = stage.score_type === "Fixed" ? 1 : this.times[0]
            
            this.hit_factor = cutDecimal(this.points / time)
        }
    }
}

export interface SimpleMatchEntity {
    id: number;
    name: string;
    tags: string[];
    openedAt: Date;
    updatedAt: Date;
}

export class SimpleMatch {
    id: number;
    name: string;
    tags: string[];
    opened_at: Date;
    updated_at: Date;
    day_since_updated: number;

    constructor(id: number, name: string, tags: string[], opened_at: Date, updated_at: Date) {
        this.id = id;
        this.name = name;
        this.tags = tags;
        this.opened_at = opened_at;
        this.updated_at = updated_at;

        this.day_since_updated = Math.ceil(Math.abs(Date.now() - this.updated_at.getTime()) / (1000 * 60 * 60 * 24));
    }

    static of(match: Match | SimpleMatchEntity): SimpleMatch{
        return new SimpleMatch(match.id, match.name, match.tags, new Date(match.openedAt), new Date(match.updatedAt))
    }
    

    public getOpendAtString(): String {
        return this.opened_at.toISOString().split('T')[0]
    }
}

export interface MatchEntity {
    id: number;
    rangeId: number;
    uploaderId: number;
    range: Range;
    name: string;
    type: string;
    category: string;
    tags: string[];
    isReflect: boolean;
    stages: Array<StageEntity>;
    shooters: Array<ShooterEntity>;
    elo: Array<EloChangeEntity>;
    openedAt: string;
    updatedAt: string;
}

export class Match {
    id: number;
    rangeId: number;
    uploaderId: number;
    range: Range;
    name: string;
    type: string;
    category: string;
    tags: string[];
    isReflect: boolean;
    stages: Array<Stage>;
    shooters: Array<Shooter>;
    elo: Array<EloChange>;
    openedAt: Date;
    updatedAt: Date;

    constructor(
        id: number,
        range_id: number, range: Range,
        uploader_id: number,
        name: string, type: string, category: string, tags: string[],
        is_reflect: boolean,
        stages: Array<Stage>,
        shooters: Array<Shooter>,
        elo: Array<EloChange>,
        openedAt: Date, updatedAt: Date
    ) {
        this.id = id;
        this.rangeId = range_id;
        this.range = range;
        this.uploaderId = uploader_id;
        this.name = name;
        this.type = type;
        this.category = category;
        this.tags = tags;
        this.isReflect = is_reflect;
        this.stages = stages;
        this.shooters = shooters;
        this.elo = elo;
        this.openedAt = openedAt;
        this.updatedAt = updatedAt;
    }

    static from(entity: MatchEntity) {
        return new Match(
            entity.id,
            entity.rangeId,
            entity.range,
            entity.uploaderId,
            entity.name,
            entity.type,
            entity.category,
            entity.tags,
            entity.isReflect,
            entity.stages.map(s => new Stage(s)),
            entity.shooters.map(s => Shooter.from(s)),
            entity.elo.map(s => EloChange.from(s)),
            new Date(entity.openedAt),
            new Date(entity.updatedAt),
        )
    }

    public getLevels(): Array<string> {
        return [... new Set(this.shooters.map(s => s.level).filter(cat => cat.length > 0))]
    }

    public getStage(stageId: number): Stage {
        return this.stages.filter(s => s.id === stageId)[0]
    }

    public getType(): String {
        // if (this.isIpscStyle()) return this.type.toUpperCase()
        if (this.isSteelChallenge()) return "Steel Challenge"
        else return this.type.toUpperCase()
    }

    public isIpscStyle(): boolean {
        return constants.IPSC_STYLE_TYPES.includes(this.type.toUpperCase())
    }

    public isSteelChallenge(): boolean {
        return this.type.toUpperCase() === constants.MATCH_TYPE_STEEL_CHALLENGE.toUpperCase()
    }
}

export class MatchUpdateRequest {
    rangeId: number;
    name: string;
    type: string;
    tags: string[];

    constructor(match: Match) {
        this.rangeId = match.rangeId;
        this.name = match.name;
        this.type = match.type;
        this.tags = match.tags;
    }
}