import { parseJwtBody } from './jwt';

export function isAdmin(authCookie: string): boolean {
    try {
        const userAuth = parseJwtBody(authCookie);
        return userAuth.isAdmin
    } catch (error) {
        console.log(error);
        return false
    }
}

export function isInfoEditable(authCookie: string, userId: number) {
    try {
        const userAuth = parseJwtBody(authCookie);
        return userAuth.isAdmin || userAuth.id === userId;
    } catch (error) {
        console.log(error);
        return false;
    }
}