export interface CarouselInfoEntity {
    id: number;
    image: string;
    mobileImage: string;
    order: number;
    active: boolean;
    link: string;
    description: string;
}

export class CarouselInfo {
    id: number;
    image: string;
    mobileImage: string;
    order: number;
    show_order: number;
    active: boolean;
    link: string;
    description: string;
    
    constructor(id: number, image: string, mobileImage: string, order: number, active: boolean, link: string, description: string) {
        this.id = id
        this.image = image
        this.mobileImage = mobileImage
        this.order = order
        this.show_order = order
        this.active = active
        this.link = link
        this.description = description
    }

    static of(entity: CarouselInfoEntity) {
        return new CarouselInfo(
            entity.id,
            entity.image,
            entity.mobileImage,
            entity.order,
            entity.active,
            entity.link,
            entity.description
        )
    }

    isFirst(): boolean {
        return this.show_order === 0
    }

    getImage(isMobile: boolean): string {
        if (isMobile) {
            return this.mobileImage ? this.mobileImage : this.image
        } else {
            return this.image
        }

    }
}