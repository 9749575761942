import { Shooter, User } from '@/components/data/shooter';
import { Match, Stage, StageScore } from './match';
import { cutDecimalD, equalIgnore } from '@/components/script/utils'

export class SteelChallengeScoreResult {
    stageId: number;
    videoLink: string;
    shooterName: string;
    userId: number;
    shooterId: number;
    shooterClass: string;
    level: string;
    division: number;
    percentage: number;
    time: number;
    times: Array<number>;
    maxTimeString: number;
    isDq: boolean;

    constructor(stageId: number,
        videoLink: string, shooterName: string, userId: number, shooterId: number,
        shooterClass: string, level: string, division: number, percentage: number, time: number, times: Array<number>,
        isDq: boolean) {
        this.stageId = stageId
        this.videoLink = videoLink
        this.shooterName = shooterName
        this.userId = userId
        this.shooterId = shooterId
        this.shooterClass = shooterClass
        this.level = level
        this.division = division
        this.percentage = percentage
        this.time = time
        this.times = times
        this.isDq = isDq

        this.maxTimeString = this.times.indexOf(Math.max(...this.times));
    }

    static fromScore(match: Match, score: StageScore, shooter: Shooter) {
        return new SteelChallengeScoreResult(
            score.stage_id,
            score.video_link,
            shooter.name,
            shooter.user_id,
            shooter.id,
            "", // shooter class
            shooter.level,
            shooter.division,
            0,
            score.getSteelChallengeTime(),
            score.times,
            score.is_dq
        )
    }

    public checkClassOption(classOption: string, userMap: Map<number, User>): boolean {
        if (equalIgnore(classOption, 'default')) return true

        const u = userMap.get(this.userId)
        if (u === undefined) return false
        if (u.classifiers.length < 0) return false
        return equalIgnore(u.classifiers[0].clazz, classOption)
    }

    public checkLevelOption(levelOption: string): boolean {
        return levelOption.toUpperCase() === 'default'.toUpperCase() ? true : equalIgnore(this.level || "", levelOption)
    }

    public add(scoreResult: SteelChallengeScoreResult): SteelChallengeScoreResult {
        this.time = this.time + scoreResult.time
        this.times = this.times.concat(scoreResult.times)

        return this
    }

    public isZeroPoint(): boolean {
        return this.isDq;
    }

    public cutFieldsDecimal() {
        this.time = cutDecimalD(this.time, 2)
        this.percentage = cutDecimalD(this.percentage, 2)
    }
}

export class SteelChallengeMatchResult {
    name: string
    divScoreResults: Map<string, Array<SteelChallengeScoreResult>>;
    shooterScoreResults: Array<SteelChallengeScoreResult>;

    constructor(name: string, divScoreResults: Map<string, Array<SteelChallengeScoreResult>>, shooterScoreResults: Array<SteelChallengeScoreResult>) {
        this.name = name;
        this.divScoreResults = divScoreResults;
        this.shooterScoreResults = shooterScoreResults
    }

    static convertToMatchResult(
        match: Match,
        userInfoMap: Map<number, User>,
        stageOption: string, divisionOption: string, classOption: string, levelOption: string,
        shooterId: number
    ): SteelChallengeMatchResult {
        if (divisionOption === "overall") {
            return SteelChallengeMatchResult.convertToOverallMatchResult(match, userInfoMap, stageOption, classOption, levelOption, shooterId)
        }

        // TODO: class 계산하는 방법 알아서 여기다 넣을 것.
        const shooterMap = new Map<number, Shooter>(match.shooters.map(s => [s.id, s]))
        const stageMap = new Map<number, Stage>(match.stages.map(s => [s.id, s]));

        const divisionStageShooterMap = new Map<number, Map<number, StageScore[]>>()

        const target_scores: Array<StageScore> = match.stages.map(s => s.scores)
            .flat()
            .filter(s => s.checkStageOption(stageOption))
            .filter(s => s.checkDivisionOption(divisionOption, shooterMap))

        target_scores.forEach(s => {
            const divisionId = shooterMap.get(s.shooter_id)!!.division
            const stageScoreMap = divisionStageShooterMap.get(divisionId) || new Map<number, StageScore[]>()
            const scores = stageScoreMap.get(s.stage_id) || Array.of()

            scores.push(s)

            stageScoreMap.set(s.stage_id, scores)
            divisionStageShooterMap.set(divisionId, stageScoreMap)
        })

        const divStageScoreResultMap = SteelChallengeMatchResult.calculateStageScoreResult(match, stageMap, shooterMap, divisionStageShooterMap)

        if (shooterId !== null) {
            const userScores = Array.from(divStageScoreResultMap.values())
                .map(stageShooterScoreMap => Array.from(stageShooterScoreMap.values()))
                .flat()
                .map(s => Array.from(s.values()).filter(ss => ss.shooterId === shooterId))
                .filter(l => l.length > 0)
                .map(s => s[0])

            userScores.forEach(s => s.cutFieldsDecimal())

            userScores.sort((a, b) => stageMap.get(a.stageId)!!.sort - stageMap.get(b.stageId)!!.sort)

            return new SteelChallengeMatchResult(
                match.name,
                new Map<string, Array<SteelChallengeScoreResult>>(),
                userScores
            )
        }

        const divTotalResultMap = SteelChallengeMatchResult.calculateTotalScore(match, divStageScoreResultMap)


        const filteredResult = new Map<string, Array<SteelChallengeScoreResult>>()

        divTotalResultMap.forEach((scores, divisionId) => {
            const filtered = scores
                .filter(s => s.checkClassOption(classOption, userInfoMap))
                .filter(s => s.checkLevelOption(levelOption))

            if (filtered.length > 0) filteredResult.set(divisionId.toString(), filtered)
        })

        return new SteelChallengeMatchResult(match.name, filteredResult, [])
    }

    static calculateStageScoreResult(
        match: Match,
        stageMap: Map<number, Stage>,
        shooterMap: Map<number, Shooter>,
        divisionStageShooterMap: Map<number, Map<number, StageScore[]>>
    ): Map<number, Map<number, Map<number, SteelChallengeScoreResult>>> {
        const result = new Map<number, Map<number, Map<number, SteelChallengeScoreResult>>>()

        divisionStageShooterMap.forEach((stageScoreMap, divisionId) => {
            stageScoreMap.forEach((scores, stageId) => {
                const minTime = Math.min(...scores.map(s => s.getSteelChallengeTime()))
                const scoreResults = scores.map(s => SteelChallengeScoreResult.fromScore(match, s, shooterMap.get(s.shooter_id)!!))

                const userScoreMap = new Map(
                    scoreResults.map(s => {
                        const percentage = minTime / s.time;
                        s.percentage = percentage * 100;
                        return [s.shooterId, s]
                    })
                )

                const stageScoreMap = result.get(divisionId) || new Map<number, Map<number, SteelChallengeScoreResult>>()
                stageScoreMap.set(stageId, userScoreMap)
                result.set(divisionId, stageScoreMap)
            })
        })

        return result
    }

    static convertToOverallMatchResult(
        match: Match,
        userInfoMap: Map<number, User>,
        stageOption: string, classOption: string, levelOption: string,
        shooterId: number
    ): SteelChallengeMatchResult {
        // TODO: class 계산하는 방법 알아서 여기다 넣을 것.
        const shooterMap = new Map<number, Shooter>(match.shooters.map(s => [s.id, s]))
        const stageMap = new Map<number, Stage>(match.stages.map(s => [s.id, s]));

        const stageShooterMap = new Map<number, StageScore[]>()

        const target_scores: Array<StageScore> = match.stages.map(s => s.scores)
            .flat()
            .filter(s => s.checkStageOption(stageOption))

        target_scores.forEach(s => {
            const scores = stageShooterMap.get(s.stage_id) || Array.of()
            scores.push(s)
            stageShooterMap.set(s.stage_id, scores)
        })

        const stageScoreResultMap = new Map<number, SteelChallengeScoreResult[]>()

        stageShooterMap.forEach((scores, stageId) => {
            const scoreResults = scores.map(s => {
                const scoreResult = SteelChallengeScoreResult.fromScore(match, s, shooterMap.get(s.shooter_id)!!)
                scoreResult.percentage = s.stage_percent

                return scoreResult
            })

            stageScoreResultMap.set(stageId, scoreResults)
        })

        if (shooterId !== null) {
            const userScores = Array.from(stageScoreResultMap.values())
                .map(s => s.filter(ss => ss.shooterId === shooterId))
                .filter(l => l.length > 0)
                .map(s => s[0])

            userScores.forEach(s => s.cutFieldsDecimal())

            userScores.sort((a, b) => stageMap.get(a.stageId)!!.sort - stageMap.get(b.stageId)!!.sort)

            return new SteelChallengeMatchResult(
                match.name,
                new Map<string, Array<SteelChallengeScoreResult>>(),
                userScores
            )
        }

        const stageIds = [...stageScoreResultMap.keys()]
        const maxParticipatedStageId = stageIds.reduce(function (prev, current) {
            return stageScoreResultMap.get(prev)!!.length > stageScoreResultMap.get(current)!!.length ? prev : current
        })

        const shooterIds = [...stageScoreResultMap.get(maxParticipatedStageId)!!.map(s => s.shooterId)]
        const totalScores = shooterIds.map(shooterId => {
            let totalScoreResult: SteelChallengeScoreResult | null = null
            stageIds.forEach(stageId => {
                const userScoreResult = stageScoreResultMap.get(stageId)!!.filter(s => s.shooterId === shooterId)
                if (userScoreResult.length > 0) {
                    if (totalScoreResult === null) {
                        totalScoreResult = userScoreResult[0]
                    } else {
                        totalScoreResult = totalScoreResult.add(userScoreResult[0])
                    }
                }
            })

            return totalScoreResult!!
        })

        totalScores.sort((a, b) => a.time - b.time)


        if (totalScores.length > 0) {
            const minTime = totalScores[0].time
            totalScores.forEach(s => {
                s.percentage = minTime / s.time
                s.cutFieldsDecimal()
            });
        }

        const r = new Map<string, SteelChallengeScoreResult[]>()
        r.set('overall', totalScores)

        totalScores
            .filter(s => s.checkClassOption(classOption, userInfoMap))
            .filter(s => s.checkLevelOption(levelOption))

        return new SteelChallengeMatchResult(match.name, r, [])
    }

    static calculateTotalScore(
        match: Match,
        divStageScoreResultMap: Map<number, Map<number, Map<number, SteelChallengeScoreResult>>>
    ): Map<number, Array<SteelChallengeScoreResult>> {
        const result = new Map<number, Array<SteelChallengeScoreResult>>();

        divStageScoreResultMap.forEach((stageScoreResultMap, divisionId) => {
            const stageIds = [...stageScoreResultMap.keys()]

            if (stageIds.length < 1) {
                return result
            }

            const maxParticipatedStageId = stageIds.reduce(function (prev, current) {
                return stageScoreResultMap.get(prev)!!.size > stageScoreResultMap.get(current)!!.size ? prev : current
            })


            const userIds = [...new Set(stageScoreResultMap.get(maxParticipatedStageId)!!.keys())]

            const totalScores = userIds.map(userId => {
                let totalScoreResult: SteelChallengeScoreResult | null = null
                stageIds.forEach(stageId => {
                    const userScoreResult = stageScoreResultMap.get(stageId)!!.get(userId)

                    if (userScoreResult) {
                        if (totalScoreResult === null) {
                            totalScoreResult = userScoreResult
                        } else {
                            totalScoreResult = totalScoreResult.add(userScoreResult)
                        }
                    }
                })

                return totalScoreResult!!
            })


            totalScores.sort((a, b) => a.time - b.time)

            if (totalScores.length > 0) {
                const minTime = totalScores[0].time
                totalScores.forEach(s => {
                    s.percentage = minTime / s.time
                    s.cutFieldsDecimal()
                });
            }

            result.set(divisionId, totalScores)
        })

        return result
    }
}
