import { Shooter, User } from '@/components/data/shooter';
import { Match, Stage, StageScore } from './match';
import { cutDecimal, cutDecimalD, equalIgnore } from '@/components/script/utils'

export class ScoreResult {
    stageId: number;
    videoLink: string;
    shooterName: string;
    userId: number;
    shooterId: number;
    stageScore: number;  // 스테이지에서 획득한 타겟 점수
    score: number;       // percentage로 계산해서 최종 획득한 스테이지 점수
    percentage: number;
    shooterClass: string;
    level: string;
    division: number;
    time: number;
    times: Array<number>;
    hitFactor: number;
    alpha: number;
    bravo: number;
    charlie: number;
    delta: number;
    miss: number;
    noPenaltyMiss: number;
    noShoot: number;
    penalty: number;
    isDq: boolean;
    powerFactor: string;

    constructor(stageId: number,
        videoLink: string, userId: number, shooterId: number, shooterName: string,
        stageScore: number, score: number, percentage: number,
        shooterClass: string, level: string, division: number, time: number, times: Array<number>, hitFactor: number,
        alpha: number, bravo: number, charlie: number, delta: number,
        miss: number, noPenaltyMiss: number, noShoot: number, penalty: number, isDq: boolean,
        powerFactor: string) {
        this.stageId = stageId
        this.videoLink = videoLink
        this.shooterName = shooterName
        this.userId = userId
        this.shooterId = shooterId
        this.stageScore = stageScore
        this.score = score
        this.percentage = percentage * 100
        this.shooterClass = shooterClass
        this.level = level
        this.division = division
        this.time = time
        this.times = times
        this.hitFactor = hitFactor
        this.alpha = alpha
        this.bravo = bravo
        this.charlie = charlie
        this.delta = delta
        this.miss = miss
        this.noPenaltyMiss = noPenaltyMiss
        this.noShoot = noShoot
        this.penalty = penalty >= 900 ? 0 : penalty
        this.isDq = isDq
        this.powerFactor = powerFactor || "MINOR"
    }

    static fromScore(match: Match, score: StageScore, shooter: Shooter) {
        return new ScoreResult(
            score.stage_id,
            score.video_link,
            shooter.user_id,
            shooter.id,
            shooter.name,
            score.points, // stage score
            0, // score
            0, // percentage
            "", // shooter class
            shooter.level,
            shooter.division,
            score.getIpscTime(),
            score.times,
            score.hit_factor,
            score.score_a + score.popper_hit,
            score.score_b,
            score.score_c,
            score.score_d,
            score.score_m + score.popper_miss,
            score.score_npm + score.popper_npm,
            score.score_ns + score.popper_no_shoot,
            score.apen,
            score.is_dq,
            score.power_factor
        )
    }

    public checkClassOption(classOption: string, userMap: Map<number, User>): boolean {
        if (equalIgnore(classOption, 'default')) return true

        const u = userMap.get(this.userId)
        if (u === undefined) return false
        if (u.classifiers[0] === undefined) {
            return false
        }
        return equalIgnore(u.classifiers[0].clazz, classOption)
    }
    public checkLevelOption(levelOption: string): boolean {
        return levelOption.toUpperCase() === 'default'.toUpperCase() ? true : equalIgnore(this.level || "", levelOption)
    }

    public add(scoreResult: ScoreResult): ScoreResult {
        this.score = this.score + scoreResult.score
        this.time = this.time + scoreResult.time
        this.times = this.times.concat(scoreResult.times)
        this.hitFactor = this.hitFactor + scoreResult.hitFactor
        this.alpha += scoreResult.alpha
        this.bravo += scoreResult.bravo
        this.charlie += scoreResult.charlie
        this.delta += scoreResult.delta
        this.miss += scoreResult.miss
        this.noShoot += scoreResult.noShoot
        return this
    }

    public isZeroPoint(): boolean {
        return this.isDq || this.hitFactor === 0;
    }

    public cutFieldsDecimal() {
        this.time = cutDecimalD(this.time, 2)
        this.score = cutDecimal(this.score)
        this.percentage = cutDecimalD(this.percentage, 2)
    }

    public calculateStageScore(stage: Stage, stageMaxHitFactor: number) {
        const stageMaxScore = stage.getMaxStageScore()

        if (stageMaxHitFactor > 0) {
            const percentage = this.hitFactor / stageMaxHitFactor;
            if (stage.score_type === "Fixed") {
                this.score = this.hitFactor
            } else {
                this.score = stageMaxScore * percentage;
            }
            this.percentage = percentage * 100;
        } else {
            this.score = 0;
            this.percentage = 0;
        }
    }
}

export class MatchResult {
    name: string
    divScoreResults: Map<string, Array<ScoreResult>>;
    shooterScoreResults: Array<ScoreResult>;

    constructor(name: string, divScoreResults: Map<string, Array<ScoreResult>>, shooterScoreResults: Array<ScoreResult>) {
        this.name = name;
        this.divScoreResults = divScoreResults;
        this.shooterScoreResults = shooterScoreResults
    }

    static convertToMatchResult(
        match: Match,
        userInfoMap: Map<number, User>,
        stageOption: string, divisionOption: string, classOption: string, levelOption: string,
        shooterId: number
    ): MatchResult {
        if (divisionOption === "overall") {
            return MatchResult.convertToOverallMatchResult(match, userInfoMap, stageOption, classOption, levelOption, shooterId)
        }

        // TODO: class 계산하는 방법 알아서 여기다 넣을 것.
        const shooterMap = new Map<number, Shooter>(match.shooters.map(s => [s.id, s]))
        const stageMap = new Map<number, Stage>(match.stages.map(s => [s.id, s]));

        const divisionStageShooterMap = new Map<number, Map<number, StageScore[]>>()

        const target_scores: Array<StageScore> = match.stages.map(s => s.scores)
            .flat()
            .filter(s => s.checkStageOption(stageOption))
            .filter(s => s.checkDivisionOption(divisionOption, shooterMap))

        target_scores.forEach(s => {
            const divisionId = shooterMap.get(s.shooter_id)!!.division
            const stageScoreMap = divisionStageShooterMap.get(divisionId) || new Map<number, StageScore[]>()
            const scores = stageScoreMap.get(s.stage_id) || Array.of()

            scores.push(s)

            stageScoreMap.set(s.stage_id, scores)
            divisionStageShooterMap.set(divisionId, stageScoreMap)
        })

        const divStageScoreResultMap = MatchResult.calculateStageScoreResult(match, stageMap, shooterMap, divisionStageShooterMap)

        if (shooterId !== null) {
            const userScores = Array.from(divStageScoreResultMap.values())
                .map(stageShooterScoreMap => Array.from(stageShooterScoreMap.values()))
                .flat()
                .map(s => Array.from(s.values()).filter(ss => ss.shooterId === shooterId))
                .filter(l => l.length > 0)
                .map(s => s[0])

            userScores.forEach(s => s.cutFieldsDecimal())
            userScores.sort((a, b) => stageMap.get(a.stageId)!!.sort - stageMap.get(b.stageId)!!.sort)

            return new MatchResult(
                match.name,
                new Map<string, Array<ScoreResult>>(),
                userScores
            )
        }

        const divTotalResultMap = MatchResult.calculateTotalScore(match, divStageScoreResultMap)


        const filteredResult = new Map<string, Array<ScoreResult>>()

        divTotalResultMap.forEach((scores, divisionId) => {
            const filtered = scores
                .filter(s => s.checkClassOption(classOption, userInfoMap))
                .filter(s => s.checkLevelOption(levelOption))

            if (filtered.length > 0) filteredResult.set(divisionId.toString(), filtered)
        })

        return new MatchResult(match.name, filteredResult, [])
    }

    static calculateStageScoreResult(
        match: Match,
        stageMap: Map<number, Stage>,
        shooterMap: Map<number, Shooter>,
        divisionStageShooterMap: Map<number, Map<number, StageScore[]>>
    ): Map<number, Map<number, Map<number, ScoreResult>>> {
        const result = new Map<number, Map<number, Map<number, ScoreResult>>>()

        divisionStageShooterMap.forEach((stageScoreMap, divisionId) => {
            stageScoreMap.forEach((scores, stageId) => {
                const maxHitFactor = Math.max(...scores.map(s => s.hit_factor))
                const stage = stageMap.get(stageId)!!
                const stageMaxScore = stageMap.get(stageId)!!.getMaxStageScore()

                const scoreResults = scores.map(s => ScoreResult.fromScore(match, s, shooterMap.get(s.shooter_id)!!))

                const userScoreMap = new Map(
                    scoreResults.map(s => {
                        s.calculateStageScore(stage, maxHitFactor)
                        return [s.shooterId, s]
                    })
                )

                const stageScoreMap = result.get(divisionId) || new Map<number, Map<number, ScoreResult>>()
                stageScoreMap.set(stageId, userScoreMap)
                result.set(divisionId, stageScoreMap)
            })
        })

        return result
    }

    static convertToOverallMatchResult(
        match: Match,
        userInfoMap: Map<number, User>,
        stageOption: string, classOption: string, levelOption: string,
        shooterId: number
    ): MatchResult {
        // TODO: class 계산하는 방법 알아서 여기다 넣을 것.
        const shooterMap = new Map<number, Shooter>(match.shooters.map(s => [s.id, s]))
        const stageMap = new Map<number, Stage>(match.stages.map(s => [s.id, s]));

        const stageScoresMap = new Map<number, StageScore[]>()

        const target_scores: Array<StageScore> = match.stages.map(s => s.scores)
            .flat()
            .filter(s => s.checkStageOption(stageOption))

        target_scores.forEach(s => {
            const scores = stageScoresMap.get(s.stage_id) || Array.of()
            scores.push(s)
            stageScoresMap.set(s.stage_id, scores)
        })

        const stageScoreResultMap = new Map<number, ScoreResult[]>()

        stageScoresMap.forEach((scores, stageId) => {
            const scoreResults = scores.map(s => {
                const scoreResult = ScoreResult.fromScore(match, s, shooterMap.get(s.shooter_id)!!)
                return scoreResult
            })

            if (scoreResults.length > 0) {
                const maxHitFactor = scoreResults.sort((a, b) => b.hitFactor - a.hitFactor)[0].hitFactor
                const stage = stageMap.get(stageId)!!
                scoreResults.forEach(s => s.calculateStageScore(stage, maxHitFactor))
            }

            stageScoreResultMap.set(stageId, scoreResults)
        })

        if (shooterId !== null) {
            const userScores = Array.from(stageScoreResultMap.values())
                .map(s => s.filter(ss => ss.shooterId === shooterId))
                .filter(l => l.length > 0)
                .map(s => s[0])

            userScores.forEach(s => s.cutFieldsDecimal())

            userScores.sort((a, b) => stageMap.get(a.stageId)!!.sort - stageMap.get(b.stageId)!!.sort)

            return new MatchResult(
                match.name,
                new Map<string, Array<ScoreResult>>(),
                userScores
            )
        }

        const stageIds = [...stageScoreResultMap.keys()]
        const maxParticipatedStageId = stageIds.reduce(function (prev, current) {
            return stageScoreResultMap.get(prev)!!.length > stageScoreResultMap.get(current)!!.length ? prev : current
        })

        const shooterIds = [...stageScoreResultMap.get(maxParticipatedStageId)!!.map(s => s.shooterId)]
        const totalScores = shooterIds.map(shooterId => {
            let totalScoreResult: ScoreResult | null = null
            stageIds.forEach(stageId => {
                const userScoreResult = stageScoreResultMap.get(stageId)!!.filter(s => s.shooterId === shooterId)
                if (userScoreResult.length > 0) {
                    if (totalScoreResult === null) {
                        totalScoreResult = userScoreResult[0]
                    } else {
                        totalScoreResult = totalScoreResult.add(userScoreResult[0])
                    }
                }
            })

            return totalScoreResult!!
        })

        totalScores.sort((a, b) => b.score - a.score)
        if (totalScores.length > 0) {
            const max_score = totalScores[0].score
            totalScores.forEach(s => {
                if (max_score > 0) {
                    s.percentage = (s.score / max_score) * 100;
                } else {
                    s.percentage = 0;
                }

                s.cutFieldsDecimal()
            });
        }

        const r = new Map<string, ScoreResult[]>()
        r.set('overall', totalScores)

        totalScores
            .filter(s => s.checkClassOption(classOption, userInfoMap))
            .filter(s => s.checkLevelOption(levelOption))

        return new MatchResult(match.name, r, [])
    }

    static calculateTotalScore(
        match: Match,
        divStageScoreResultMap: Map<number, Map<number, Map<number, ScoreResult>>>
    ): Map<number, Array<ScoreResult>> {
        const result = new Map<number, Array<ScoreResult>>();

        divStageScoreResultMap.forEach((stageScoreResultMap, divisionId) => {
            const stageIds = [...stageScoreResultMap.keys()]

            if (stageIds.length < 1) {
                return result
            }

            const maxParticipatedStageId = stageIds.reduce(function (prev, current) {
                return stageScoreResultMap.get(prev)!!.size > stageScoreResultMap.get(current)!!.size ? prev : current
            })


            const userIds = [...new Set(stageScoreResultMap.get(maxParticipatedStageId)!!.keys())]

            const totalScores = userIds.map(userId => {
                let totalScoreResult: ScoreResult | null = null
                stageIds.forEach(stageId => {
                    const userScoreResult = stageScoreResultMap.get(stageId)!!.get(userId)

                    if (userScoreResult) {
                        if (totalScoreResult === null) {
                            totalScoreResult = userScoreResult
                        } else {
                            totalScoreResult = totalScoreResult.add(userScoreResult)
                        }
                    }
                })

                return totalScoreResult!!
            })

            totalScores.sort((a, b) => b.score - a.score)

            if (totalScores.length > 0) {
                const max_score = totalScores[0].score
                totalScores.forEach(s => {
                    if (max_score > 0) {
                        s.percentage = (s.score / max_score) * 100;
                    } else {
                        s.percentage = 0;
                    }

                    s.cutFieldsDecimal()
                });
            }

            result.set(divisionId, totalScores)
        })

        return result
    }
}