import { createApp } from 'vue'
import router from '@/router'
import App from '@/App.vue'
import VueCookies from "vue3-cookies"
import { globalCookiesConfig } from "vue3-cookies";
import Vue3MobileDetection from "vue3-mobile-detection";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"

globalCookiesConfig({
  expireTimes: "5h",
  path: "/airsix",
  domain: "www.airsix.com",
  secure: true,
  sameSite: "None",
});

export const app = createApp(App)
app.use(router)
app.use(Vue3MobileDetection)
app.use(VueCookies)
app.mount('#app')
