import { CarouselInfo } from './components/data/carousel';
import { Division, DivisionUpdateRequest } from './components/data/division';
import { Sport, SportUpdateRequest } from './components/data/sport';
import { Range } from './components/data/range';
import { User, AdminPageUser, UserEntity, UserMergeRequest, MatchCheckResult, MatchCheckResultEntity, MatchMergeCheckResultEntity, MatchMergeCheckResult } from './components/data/shooter';
import { SimpleMatch, Match, MatchEntity, Stage, MatchUpdateRequest } from './components/data/matchResult/match';
import { Shooter, ShooterEntity } from '@/components/data/shooter';
import { CountResponse, Pagination } from '@/components/data/response';
import { VideoLinkRequest, VideoLinkRequestEntity } from '@/components/data/videoLinkRequest';
import axios from "axios";

export const airsixAdminAxios = axios.create({
    // baseURL: "https://air-six.com",
    headers: {
        "content-type": "application/json",
        Accept: "application/json"
    }
})

export const uploadMatch = (data: FormData) => airsixAdminAxios.post<MatchEntity>(`/api/admin/matches`, data,
    {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
).then(r => Match.from(r.data));

export const checkNewMatchUser = (data: FormData) => airsixAdminAxios.post<MatchCheckResultEntity>(`/api/admin/matches/upload-match-check`, data,
{
    headers: {
        "Content-Type": "multipart/form-data",
    }
}
).then(r => MatchCheckResult.from(r.data));

export const updateMatch = (match_id: number, data: MatchUpdateRequest) => airsixAdminAxios.patch<CountResponse>(`/api/admin/matches/${match_id}`, data);

export const deleteMatch = async (match_id: number) => airsixAdminAxios.delete<string>(`/api/admin/matches/${match_id}`)

export const mergeMatchesTest = async (title: string, match_ids: Array<string>) => 
    airsixAdminAxios.post<MatchMergeCheckResultEntity>(`/api/admin/matches/make-merged-match-test?title=${title}&match_ids=${match_ids.join('&match_ids=')}`)
    .then(r => MatchMergeCheckResult.from(r.data));

export const mergeMatches = async (title: string, match_ids: Array<string>) => 
    airsixAdminAxios.post<MatchEntity>(`/api/admin/matches/make-merged-match?title=${title}&match_ids=${match_ids.join('&match_ids=')}`)
    .then(r => Match.from(r.data));

export const updateStage = (stage_id: number, data: Stage) => airsixAdminAxios.patch<Stage>(`/api/admin/stages/${stage_id}`, data);

export const registerStageImage =  async (stage_id: number, data: FormData) =>
    airsixAdminAxios.patch(`/api/admin/stages/${stage_id}/stage-image`, data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    );

export const deleteStageImage =  async (stage_id: number) => airsixAdminAxios.delete(`/api/admin/stages/${stage_id}/stage-image`);

export const getUsers  = (page: number, size: number, name: string) => {
    let uri = `/api/admin/users?page=${page}&size=${size}&sort=ID&order=ASC&is_admin=1`

    if (name.length > 0) {
        uri = `${uri}&userName=${name}`
    }

    return airsixAdminAxios.get<Pagination<UserEntity>>(uri).then((r) => {
        return new Pagination<AdminPageUser>(
            r.data.totalPage,
            r.data.currentPage,
            r.data.data.map(e => AdminPageUser.from(e))
        )
    })
}

export const getUserById = (userId: number) => airsixAdminAxios.get<UserEntity>(`/api/admin/users/${userId}`).then((r) => AdminPageUser.from(r.data))

export const getUserByIds = async (userIds: Array<number>) => {
    const r = await airsixAdminAxios.get<Array<UserEntity>>(`/api/users/ids?userIds=${userIds.join('&userIds=')}&is_admin=True`)
    return r.data.map(e => AdminPageUser.from(e))
}

export const updateUserInfo = (user: AdminPageUser) => airsixAdminAxios.request({
    url: `/api/admin/users/${user.id}`,
    method: 'patch',
    data: user
})

export const deleteUserProfileImage = async (userId: number) => airsixAdminAxios.request({
    url: `/api/admin/users/${userId}/profile-image`,
    method: 'delete'
})

export const registerShootersWithEmail = (request: object) => airsixAdminAxios.post<number[]>('/api/admin/users/register-by-email', request)

export const mergeUser = (request: UserMergeRequest) => airsixAdminAxios.post<Object>(`/api/admin/users/merge`, request)

export const updateSport = async (sport_id: number, request: SportUpdateRequest) => airsixAdminAxios.patch<Sport>(`/api/admin/sports/${sport_id}`, request);

export const updateDivision = (division_id: number, request: DivisionUpdateRequest) => airsixAdminAxios.patch<Division>(`/api/admin/divisions/${division_id}`, request);

export const getRanges = async () => (await airsixAdminAxios.get<Range[]>('/api/admin/ranges')).data;

export const makeNewRange = async (request: object) => (await airsixAdminAxios.post<Range>('/api/admin/ranges', request)).data;

export const updateRange = async (range_id: number, request: object) => (await airsixAdminAxios.patch<Range>(`/api/admin/ranges/${range_id}`, request)).data;

export const deleteRange = async (range_id: number) => (await airsixAdminAxios.delete(`/api/admin/ranges/${range_id}`)).data;

export const getShootersWithoutEmail  = (page: number, size: number, name: string) => {
    let uri = `/api/admin/shooters/shooter-without-userdata?page=${page}&size=${size}`

    if (name.length > 0) {
        uri = `${uri}&name=${name}`
    }

    return airsixAdminAxios.get<Pagination<ShooterEntity>>(uri).then((r) => {
        return new Pagination<Shooter>(
            r.data.totalPage,
            r.data.currentPage,
            r.data.data.map(e => Shooter.from(e))
        )
    })
}

export const getUserVideoLinkRequests = (page: number = 1, size: number = 10) => 
    airsixAdminAxios.get<Pagination<VideoLinkRequestEntity>>(`/api/admin/video-requests?page=${page}&size=${size}`)
    .then(r => {
        return new Pagination<VideoLinkRequest>(
            r.data.totalPage,
            r.data.currentPage,
            r.data.data.map(e => VideoLinkRequest.from(e))
        )
    });

export const updateUserVideoLinkRequests = (_id: number, progress: string, reject_reason: string) => 
    airsixAdminAxios.patch<VideoLinkRequestEntity>(
        `/api/admin/video-requests`,
        {"_id": _id, "progress": progress, "reject_reason": reject_reason}
    ).then(r =>  VideoLinkRequest.from(r.data));


export const getAllCarouselData = () => airsixAdminAxios.get<CarouselInfo[]>(`/api/admin/carousels`)

export const createCarouselData = (data: FormData) => airsixAdminAxios.post<CarouselInfo>(`/api/admin/carousels`, data,
{
    headers: {
        "Content-Type": "multipart/form-data",
    }
}
)

export const updateCarouselData = (carousel_id: number, data: FormData) => airsixAdminAxios.patch<CarouselInfo[]>(`/api/admin/carousels/${carousel_id}`, data,
{
    headers: {
        "Content-Type": "multipart/form-data",
    }
}
)

export const deleteCarouselData = (carousel_id: number) => airsixAdminAxios.delete<Object>(`/api/admin/carousels/${carousel_id}`)
    